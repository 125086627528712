@import '../../Scss/main.scss';

.history-inputs .MuiInputBase-root.MuiOutlinedInput-root{
height: 50px;
}
.history-inputs .MuiFormControl-root{
    // width: 100%;
    min-width: 231px;
}
.history-inputs .MuiInputBase-root.MuiOutlinedInput-root input{
padding: 0 10px !important;
height: auto;
color: var(--clr-font) !important;
}
.cmn-btn {
    font-size: 15px;
    padding: 11px 40px !important;
    margin-top: 1px;
    color: #fff;
    background: rgba(255, 112, 40, 1) !important;
    transition: none !important;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
}
.history-inputs .form-group{
    display: $flex;
    gap: $gap-10;
    justify-content: flex-end;
}
.searchfrm {
    margin: 0px;
    margin-top: 0;
    margin-bottom: 0;
    display: flex !important;
    flex-wrap: inherit ;
    width: 100%;
    flex-direction: row;
    padding: 25px 20px;
    position: relative;
    z-index: 99;
    // gap: 10px;
    align-items: flex-end;
   
}
.history-inputs .pt{
    padding-top: 8px;
}
.dark-theme .order-history .history-inputs fieldset{
    border: 1px solid #ccc !important;
    border-radius: 5px;
}
.dark-theme .order-history .history-inputs label, .dark-theme .order-history .history-inputs svg.MuiSvgIcon-root{
color: var(--clr-font);
}
.dark-theme .order-history .history-inputs .MuiOutlinedInput-input{
    color: var(--clr-font) !important;
}

.pagination ul li button.Mui-selected {
    color: var(--clr-font)!important;
    background-color: #ff7028!important;
}

.pagination ul li button{
    color: var(--clr-font) !important;
    border: 1px solid #ff7028;
}  

.pagination ul{
    justify-content: center !important;
    gap: 10px !important;
}

.slide-sel .MuiFormLabel-root {
    top: 6px !important;
}


.slide-sel .MuiFormLabel-root.Mui-focused{
    top: 0px !important;
}